import * as i0 from '@angular/core';
import { Injectable, RendererStyleFlags2, Inject, EventEmitter, Directive, Input, Output, InjectionToken, NgModule } from '@angular/core';
import { DOCUMENT } from '@angular/common';
const DEFAULT_RESPONSIVE_SIZES = {
  xs: {
    xs: 2,
    sm: 2,
    md: 3,
    lg: 3,
    xl: 4
  },
  sm: {
    xs: 3,
    sm: 3,
    md: 4,
    lg: 4,
    xl: 5
  },
  md: {
    xs: 4,
    sm: 4,
    md: 5,
    lg: 5,
    xl: 6
  },
  lg: {
    xs: 5,
    sm: 5,
    md: 6,
    lg: 6,
    xl: 7
  },
  xl: {
    xs: 6,
    sm: 6,
    md: 7,
    lg: 7,
    xl: 8
  }
};
// https://material.io/design/layout/responsive-layout-grid.html#breakpoints
const CDK_BREAKPOINTS = {
  sm: 600,
  md: 1024,
  lg: 1440,
  xl: 1920
};
// https://github.com/angular/flex-layout/wiki/Responsive-API
const FX_LAYOUT_BREAKPOINTS = {
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920
};
// https://getbootstrap.com/docs/4.5/layout/overview/#responsive-breakpoints
const BOOTSTRAP_BREAKPOINTS = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200
};
// https://tailwindcss.com/docs/responsive-design
const TAILWIND_BREAKPOINTS = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280
};
const DEFAULT_BREAKPOINTS = BOOTSTRAP_BREAKPOINTS;
const isIntValue = value => {
  return !isNaN(parseFloat(value)) && !isNaN(Number(value)) && Number.isInteger(Number(value));
};
/**
 *
 * @param value can be anything
 * @param fallbackValue integer value or 0
 * @description ispired by https://github.com/angular/components/blob/master/src/cdk/coercion/number-property.ts
 */
const coerceIntProperty = (value, fallbackValue = 0) => {
  return isIntValue(value) ? Number(value) : fallbackValue;
};
/**
 *
 * @param value anything
 * @returns valid value or undefined
 */
const coerceNgxCutSizes = value => {
  if (['xs', 'sm', 'md', 'lg', 'xl'].includes(value)) {
    return value;
  } else {
    const v = coerceIntProperty(value);
    if (v > 0) {
      return v;
    }
    return 1;
  }
};
/**
 *
 * @param value number or object
 * @returns object
 */
const normalizeResponsiveSize = value => {
  if (!value) {
    return {
      xs: 1,
      sm: 1,
      md: 1,
      lg: 1,
      xl: 1
    };
  } else if (typeof value === 'number') {
    let v = value = coerceIntProperty(value);
    if (v < 1) {
      v = 1;
    }
    return {
      xs: v,
      sm: v,
      md: v,
      lg: v,
      xl: v
    };
  } else {
    const v = value;
    v.xs = v.xs < 1 ? 1 : v.xs;
    v.sm = v.sm < 1 ? 1 : v.sm;
    v.md = v.md < 1 ? 1 : v.md;
    v.lg = v.lg < 1 ? 1 : v.lg;
    v.xl = v.xl < 1 ? 1 : v.xl;
    return v;
  }
};
/**
 *
 * @param value responsive options for all size
 * @returns notmalized values, it means size > 0
 */
const normalizeAllResponsiveSizes = value => {
  if (!value) {
    return undefined;
  }
  return {
    xs: normalizeResponsiveSize(value.xs),
    sm: normalizeResponsiveSize(value.sm),
    md: normalizeResponsiveSize(value.md),
    lg: normalizeResponsiveSize(value.lg),
    xl: normalizeResponsiveSize(value.xl)
  };
};
/**
 *
 * @param breakpoints custom breakpoints
 */
const selectBreakpoints = breakpoints => {
  if (breakpoints && typeof breakpoints === 'string') {
    const bp = String(breakpoints);
    switch (bp) {
      case 'BOOTSTRAP':
        return BOOTSTRAP_BREAKPOINTS;
      case 'TAILWIND':
        return TAILWIND_BREAKPOINTS;
      case 'CDK':
        return CDK_BREAKPOINTS;
      case 'FX_LAYOUT':
        return FX_LAYOUT_BREAKPOINTS;
    }
  } else if (breakpoints && typeof breakpoints === 'object') {
    return breakpoints;
  }
  return DEFAULT_BREAKPOINTS;
};
/**
 *
 * @param breakpoints breakpoint for used in media querries
 * @param responsiveSizes how many lines should be truncated for each brakpoint
 * @returns dto object used for creating stylesheet
 */
const extractStyleSheetData = (breakpoints, responsiveSizes) => {
  if (!breakpoints || !responsiveSizes) {
    return undefined;
  }
  const {
    xs,
    sm,
    md,
    lg,
    xl
  } = responsiveSizes;
  return {
    xs: {
      xs: {
        size: xs.xs,
        breakpoint: null
      },
      sm: {
        size: xs.sm,
        breakpoint: breakpoints.sm
      },
      md: {
        size: xs.md,
        breakpoint: breakpoints.md
      },
      lg: {
        size: xs.lg,
        breakpoint: breakpoints.lg
      },
      xl: {
        size: xs.xl,
        breakpoint: breakpoints.xl
      }
    },
    sm: {
      xs: {
        size: sm.xs,
        breakpoint: null
      },
      sm: {
        size: sm.sm,
        breakpoint: breakpoints.sm
      },
      md: {
        size: sm.md,
        breakpoint: breakpoints.md
      },
      lg: {
        size: sm.lg,
        breakpoint: breakpoints.lg
      },
      xl: {
        size: sm.xl,
        breakpoint: breakpoints.xl
      }
    },
    md: {
      xs: {
        size: md.xs,
        breakpoint: null
      },
      sm: {
        size: md.sm,
        breakpoint: breakpoints.sm
      },
      md: {
        size: md.md,
        breakpoint: breakpoints.md
      },
      lg: {
        size: md.lg,
        breakpoint: breakpoints.lg
      },
      xl: {
        size: md.xl,
        breakpoint: breakpoints.xl
      }
    },
    lg: {
      xs: {
        size: lg.xs,
        breakpoint: null
      },
      sm: {
        size: lg.sm,
        breakpoint: breakpoints.sm
      },
      md: {
        size: lg.md,
        breakpoint: breakpoints.md
      },
      lg: {
        size: lg.lg,
        breakpoint: breakpoints.lg
      },
      xl: {
        size: lg.xl,
        breakpoint: breakpoints.xl
      }
    },
    xl: {
      xs: {
        size: xl.xs,
        breakpoint: null
      },
      sm: {
        size: xl.sm,
        breakpoint: breakpoints.sm
      },
      md: {
        size: xl.md,
        breakpoint: breakpoints.md
      },
      lg: {
        size: xl.lg,
        breakpoint: breakpoints.lg
      },
      xl: {
        size: xl.xl,
        breakpoint: breakpoints.xl
      }
    }
  };
};
/**
 *
 * @param key used for class name
 * @param data used for parameters
 * @returns css stylesheet based on input data
 */
const createCss = (key, data) => {
  const sheet = [];
  for (const [k, v] of Object.entries(data)) {
    if (v.breakpoint) {
      sheet.push(`
      @media screen and (min-width:${v.breakpoint}px) {
        .ngx-cut-${key} {
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: ${v.size};
          -webkit-box-orient: vertical;
        }
      }`);
    } else {
      sheet.push(`
      .ngx-cut-${key} {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: ${v.size};
        -webkit-box-orient: vertical;
      }`);
    }
  }
  return sheet.join('\n');
};
class NgxCutOptionsService {
  constructor() {
    /**
     * @returns breakpoints - can be custom or predefined
     */
    this.breakpoints = DEFAULT_BREAKPOINTS;
    /**
     * @returns number of truncated lines globaly
     */
    this.size = 1;
    /**
     * @returns aliases with defined number of lines for each breakpoint
     */
    this.responsiveSizes = DEFAULT_RESPONSIVE_SIZES;
  }
  static {
    this.ɵfac = function NgxCutOptionsService_Factory(t) {
      return new (t || NgxCutOptionsService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: NgxCutOptionsService,
      factory: NgxCutOptionsService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxCutOptionsService, [{
    type: Injectable
  }], null, null);
})();
class NgxCutService {
  constructor(document, options) {
    this.document = document;
    this.options = options;
    this.createStyleSheet();
  }
  setStyle(element, renderer, lines) {
    switch (lines) {
      case 0:
        this.resetStyle(element, renderer);
        break;
      case 1:
        this.setSingleLineStyle(element, renderer);
        break;
      default:
        this.setMultiLineStyle(element, renderer, lines);
        break;
    }
  }
  setSingleLineStyle(element, renderer) {
    const el = element.nativeElement;
    renderer.setStyle(el, 'overflow', 'hidden', RendererStyleFlags2.Important);
    renderer.setStyle(el, 'text-overflow', 'ellipsis', RendererStyleFlags2.Important);
    renderer.setStyle(el, 'white-space', 'nowrap', RendererStyleFlags2.Important);
  }
  setMultiLineStyle(element, renderer, lines) {
    const el = element.nativeElement;
    renderer.setStyle(el, 'overflow', 'hidden', RendererStyleFlags2.Important);
    renderer.setStyle(el, 'display', '-webkit-box', RendererStyleFlags2.Important);
    renderer.setStyle(el, '-webkit-line-clamp', lines, RendererStyleFlags2.Important);
    renderer.setStyle(el, '-webkit-box-orient', 'vertical', RendererStyleFlags2.Important);
  }
  resetStyle(element, renderer) {
    const el = element.nativeElement;
    renderer.removeStyle(el, 'overflow', RendererStyleFlags2.Important);
    renderer.removeStyle(el, 'text-overflow', RendererStyleFlags2.Important);
    renderer.removeStyle(el, 'white-space', RendererStyleFlags2.Important);
    renderer.removeStyle(el, 'display', RendererStyleFlags2.Important);
    renderer.removeStyle(el, '-webkit-line-clamp', RendererStyleFlags2.Important);
    renderer.removeStyle(el, '-webkit-box-orient', RendererStyleFlags2.Important);
  }
  setClass(element, renderer, size) {
    const el = element.nativeElement;
    if (size) {
      this.resetClass(element, renderer);
      renderer.addClass(el, `ngx-cut-${size}`);
    } else {
      this.resetClass(element, renderer);
    }
  }
  resetClass(element, renderer) {
    const el = element.nativeElement;
    renderer.removeClass(el, 'ngx-cut-xs');
    renderer.removeClass(el, 'ngx-cut-sm');
    renderer.removeClass(el, 'ngx-cut-md');
    renderer.removeClass(el, 'ngx-cut-lg');
    renderer.removeClass(el, 'ngx-cut-xl');
  }
  createStyleSheet() {
    const style = this.document.createElement('style');
    const data = extractStyleSheetData(this.options.breakpoints, this.options.responsiveSizes);
    style.appendChild(this.document.createTextNode(createCss('xs', data.xs)));
    style.appendChild(this.document.createTextNode(createCss('sm', data.sm)));
    style.appendChild(this.document.createTextNode(createCss('md', data.md)));
    style.appendChild(this.document.createTextNode(createCss('lg', data.lg)));
    style.appendChild(this.document.createTextNode(createCss('xl', data.xl)));
    this.document.head.appendChild(style);
  }
  static {
    this.ɵfac = function NgxCutService_Factory(t) {
      return new (t || NgxCutService)(i0.ɵɵinject(DOCUMENT), i0.ɵɵinject(NgxCutOptionsService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: NgxCutService,
      factory: NgxCutService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxCutService, [{
    type: Injectable
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }, {
    type: NgxCutOptionsService
  }], null);
})();

/**
 * @returns multiline truncate
 */
class NgxCutDirective {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  set setLines(value) {
    const v = coerceNgxCutSizes(value);
    this.size = v ? v : this.options.size;
    this.truncate();
    this.detectChanges();
  }
  // eslint-disable-next-line @angular-eslint/no-input-rename
  set setTruncate(value) {
    this.truncateDisabled = value;
    this.truncate();
    this.detectChanges();
  }
  constructor(element, renderer, options, service) {
    this.element = element;
    this.renderer = renderer;
    this.options = options;
    this.service = service;
    this.size = this.options.size;
    this.truncateDisabled = false;
    this.truncateChange = new EventEmitter();
  }
  ngOnInit() {
    this.truncate();
    this.detectChanges();
  }
  truncate() {
    if (this.element) {
      if (this.size) {
        if (typeof this.size === 'number') {
          this.service.setStyle(this.element, this.renderer, this.truncateDisabled ? 0 : Number(this.size));
        } else {
          this.service.setClass(this.element, this.renderer, this.truncateDisabled ? undefined : this.size);
        }
      }
    }
  }
  detectChanges() {
    setTimeout(() => {
      if (this.element) {
        const {
          offsetHeight,
          scrollHeight
        } = this.element.nativeElement;
        this.truncateChange.emit({
          truncated: offsetHeight < scrollHeight,
          size: this.size,
          truncateDisabled: this.truncateDisabled,
          offsetHeight,
          scrollHeight
        });
      }
    }, 100);
  }
  static {
    this.ɵfac = function NgxCutDirective_Factory(t) {
      return new (t || NgxCutDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(NgxCutOptionsService), i0.ɵɵdirectiveInject(NgxCutService));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NgxCutDirective,
      selectors: [["", "ngxCut", ""]],
      inputs: {
        setLines: [i0.ɵɵInputFlags.None, "size", "setLines"],
        setTruncate: [i0.ɵɵInputFlags.None, "truncateDisabled", "setTruncate"]
      },
      outputs: {
        truncateChange: "truncateChange"
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxCutDirective, [{
    type: Directive,
    args: [{
      selector: '[ngxCut]'
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i0.Renderer2
  }, {
    type: NgxCutOptionsService
  }, {
    type: NgxCutService
  }], {
    setLines: [{
      type: Input,
      args: ['size']
    }],
    setTruncate: [{
      type: Input,
      args: ['truncateDisabled']
    }],
    truncateChange: [{
      type: Output
    }]
  });
})();
const ngxCutOptionsFactory = options => {
  const ngxCutOptionsService = new NgxCutOptionsService();
  if (options) {
    if (options.size) {
      ngxCutOptionsService.size = options.size;
    }
    if (options.responsiveSizes) {
      ngxCutOptionsService.responsiveSizes = normalizeAllResponsiveSizes(options.responsiveSizes);
    }
    if (options.breakpoints) {
      ngxCutOptionsService.breakpoints = selectBreakpoints(options.breakpoints);
    }
  }
  return ngxCutOptionsService;
};
let FOR_ROOT_OPTIONS_TOKEN = new InjectionToken('forRoot() NgxCutOptionsService configuration.');
class NgxCutModule {
  static forRoot(options) {
    return {
      ngModule: NgxCutModule,
      providers: [{
        provide: FOR_ROOT_OPTIONS_TOKEN,
        useValue: options
      }, {
        provide: NgxCutOptionsService,
        useFactory: ngxCutOptionsFactory,
        deps: [FOR_ROOT_OPTIONS_TOKEN]
      }]
    };
  }
  static {
    this.ɵfac = function NgxCutModule_Factory(t) {
      return new (t || NgxCutModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgxCutModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [NgxCutService, NgxCutOptionsService]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxCutModule, [{
    type: NgModule,
    args: [{
      declarations: [NgxCutDirective],
      exports: [NgxCutDirective],
      providers: [NgxCutService, NgxCutOptionsService]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ngx-cut
 */

/**
 * Generated bundle index. Do not edit.
 */

export { BOOTSTRAP_BREAKPOINTS, CDK_BREAKPOINTS, DEFAULT_BREAKPOINTS, DEFAULT_RESPONSIVE_SIZES, FOR_ROOT_OPTIONS_TOKEN, FX_LAYOUT_BREAKPOINTS, NgxCutDirective, NgxCutModule, NgxCutService, TAILWIND_BREAKPOINTS };
